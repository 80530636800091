<template>
  <div id="app">
    <NotificationManager />
    <router-view></router-view>
  </div>
</template>

<script>
import NotificationManager from './components/NotificationManager.vue';

export default {
  name: 'App',
  components: {
    NotificationManager
  }
}
</script>
