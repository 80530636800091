import api from '@/store/api';
import axios from 'axios';





const authModule = {
  state: {
    userLoggedIn: false
  },
  getters: {
    userLoggedIn: state => state.userLoggedIn,
  },
  mutations: {
    SET_USER_LOGGED_IN(state, loggedIn) {
      state.userLoggedIn = loggedIn;
    },
  },
  actions: {
    async checkUserLoggedIn({commit}) {
      try {
        const response = await api.get("authentication/check-auth/");
        commit('SET_USER_LOGGED_IN', response.data.authenticated);
      } catch (error) {
        console.error('Erreur lors de la vérification de l\'état de connexion:', error);
        commit('SET_USER_LOGGED_IN', false);
      }
    },
    async logout({commit}) {
      try {
        await api.post('authentication/logout/');
        commit('SET_USER_LOGGED_IN', false);
      } catch (error) {
        console.error('Erreur lors de la déconnexion:', error);
      }
    },
    async login({ commit }, credentials) {
      try {
        const response = await api.post('authentication/login/', credentials);
        commit('SET_USER_LOGGED_IN', response.status === 200);
        // Retourne true si le statut de la réponse est 200 (succès)
        return response.status === 200;
      } catch (error) {
        console.error('Erreur lors de la connexion:', error);
        commit('SET_USER_LOGGED_IN', false);
        // Retourne false en cas d'erreur
        return false;
      }
    },
    async register({ commit }, payload) {
      try {
        const response = await api.post('authentication/register/', payload);
        commit('SET_USER_LOGGED_IN', response.status === 201);
        return response.status === 201;
      } catch (error) {
        console.error('Erreur lors de l\'enregistrement:', error);
        commit('SET_USER_LOGGED_IN', false);
        return false;
      }
    },
    async discordLogin({ commit }, { code, state }) {
      try {
        const response = await axios.post("https://edraft.games/authentication/discordLogin/", { code, state }, { withCredentials: true
        });
        commit('SET_USER_LOGGED_IN', response.status === 200);
      } catch (error) {
        console.error('Erreur lors de la connexion Discord:', error);
        commit('SET_USER_LOGGED_IN', false);
        throw error;
      }
    },

    async refreshTokens({ commit }) {
      try {
        const response = await axios.post("https://edraft.games/authentication/refresh-token/", {}, { withCredentials: true });
        commit('SET_USER_LOGGED_IN', response.status === 200);
        if (response.status === 200) {
          return response; // Renvoie l'objet de réponse entier
        } else {
          throw new Error('Failed to refresh token');
        }
      } catch (error) {
        console.error('Erreur lors du rafraîchissement des tokens:', error);
        commit('SET_USER_LOGGED_IN', false);
        throw error;
      }
    },

  },
};

export default authModule;
