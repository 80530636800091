import { createStore } from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import authModule from './modules/authModule';

export default createStore({
  modules: {
    auth: authModule,
  },
  plugins: [createPersistedState({
    paths: ['auth.userLoggedIn']
  })],
  state: {
    notifications: [],
  },
  mutations: {
    ADD_NOTIFICATION(state, notification) {
      state.notifications.push(notification);
    },
    CLEAR_NOTIFICATIONS(state) {
      state.notifications = [];
    },
  },
  actions: {
    addNotification({ commit }, notification) {
      commit('ADD_NOTIFICATION', notification);
    },
    clearNotifications({ commit }) {
      commit('CLEAR_NOTIFICATIONS');
    },
  },
});
