<template>
  <!-- Ce composant n'a pas besoin de contenu de template car il gère les notifications de manière programmatique -->
   <div></div>
</template>

<script>

import { useToast } from "vue-toastification";

export default {
  // Données du composant
  data() {
    return {
      notifications: [], // Variable pour stocker les notifications
    };
  },
  // Méthodes du composant
  methods: {
    handleNotifications(newNotifications) {
      // Traitement des nouvelles notifications
          if (newNotifications.length === 0) {
      return;
    }
      const toast = useToast();
      newNotifications.forEach(notification => {
        switch(notification.type) {
          case 'error':
            toast.error(notification.message);
            break;
          case 'success':
            toast.success(notification.message);
            break;
          case 'info':
            toast.info(notification.message);
            break;
          default:
            toast.info(notification.message);
            break;
        }
      });
      // Vider les notifications du store Vuex
      const store = this.$store;
      store.dispatch('clearNotifications');
    },
  },
  // Surveillance des notifications
  watch: {
    '$store.state.notifications': {
      handler: 'handleNotifications',
      deep: true
    },
  },
};
</script>
<style scoped>
</style>
