import { createApp } from 'vue';
import App from './App.vue';
import router from '@/router';
import store from './store/store';
import axios from 'axios'; // Assure-toi d'importer Axios
import Toast, { POSITION } from "vue-toastification";
import "vue-toastification/dist/index.css";
import '@/assets/styles.css';
import Vue3Marquee from 'vue3-marquee';

const app = createApp(App);

axios.defaults.baseURL = 'https://edraft.games/';

app.config.globalProperties.$axios = axios;

const toastOptions = {
  transition: "Vue-Toastification__bounce",
  maxToasts: 20,
  newestOnTop: true,
  position: POSITION.BOTTOM_RIGHT,
};

app.use(Vue3Marquee);
app.use(Toast, toastOptions);
app.use(router);
app.use(store);

app.mount('#app');
