import { createRouter, createWebHistory } from 'vue-router';

const routes = [
  {
    path: '/',
    name: 'landingPage',
    component: () => import('@/views/landingPage.vue')
  },
  {
    path: '/Login',
    name: 'Login',
    component: () => import('@/views/UserLogin.vue')
  },
  {
    path: '/Register',
    name: 'Register',
    component: () => import('@/views/UserRegister.vue')
  },
  {
    path: '/form',
    name: 'formDiscord',
    component: () => import('@/views/formDiscord.vue')
  },
  {
    path: '/auth/discord/callback',
    name: 'discord-callback',
    component: () => import('@/views/DiscordCallback.vue')
  },
  {
    path: '/profile',
    name: 'UserProfile',
    component: () => import('@/views/UserProfil.vue')
  },
  {
    path: '/change-password',
    name: 'ChangePassword',
    component: () => import('@/views/ChangePassword.vue')
  },
  {
    path: '/Formulaire',
    name: 'Formulaire',
    component: () => import('@/views/FormulaireDraft.vue')
  },
  {
    path: '/RequestPasswordReset',
    name: 'RequestPasswordReset',
    component: () => import("@/views/RequestPasswordReset.vue")
  },
  {
    path: '/ResetPassword/:key',
    name: 'ResetPassword',
    component: () => import('@/views/ResetPassword.vue')
  },
  {
    path: "/verify-email",
    name: "verify-email",
    component: () => import("@/views/VerifyEmail.vue")
  },
  {
    path: "/confirm-email/:key",
    name: "confirm-email",
    component: () => import("@/views/ConfirmEmail.vue")
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  }
});

export default router;

