import axios from 'axios';
import store from '@/store/store'; // Assure-toi que ce chemin est correct
import router from '@/router'; // Ajoute l'importation du router

const api = axios.create({
  baseURL: 'https://edraft.games/',
  withCredentials: true,
});

api.interceptors.response.use(
    response => {
      return response;
    },
    async (error) => {
      const originalRequest = error.config;

      if (error.response.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;

        try {
          const refreshResponse = await store.dispatch('refreshTokens');
          if (refreshResponse.status === 200) {
            return api(originalRequest);
          } else {
            throw new Error('Refresh token failed');
          }
        } catch (refreshError) {
          console.error('Erreur lors du rafraîchissement des tokens:', refreshError);
          store.commit('SET_USER_LOGGED_IN', false);

          // Redirection vers la page de login
          router.push('/login').catch(err => {
            console.error('Erreur de redirection:', err);
          });

          return Promise.reject(refreshError);
        }
      }
      return Promise.reject(error);
    }
);

export default api;
